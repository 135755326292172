import LoaderManagerClass  from "./lib/LoaderManager.js";

/**
 * Gestione caricamento pagina
 * Primo codice js che va in esecuzione
 */

window.LoaderManager = new LoaderManagerClass;

/**
 * Aggiungo evento "site_loading" attraverso LoaderManager
 * La rimozione avviene alla fine della sezione "document ready"
 */
LoaderManager.addEvent("site_loading");

/**
 * Prima che qualsiasi contenuto venga renderizzato, abilita js
 * (sostituisco classe "no-js" con "js")
 */ 
document.documentElement.classList.add('js');        
document.documentElement.classList.remove('no-js');        

/**
 * classe per riconoscimento touch
 * Al primo evento touch aggiungo la classe touch
 */
document.documentElement.classList.add('no-touch');
window.addEventListener("touchstart", function() {
    document.documentElement.classList.add('touch');
    document.documentElement.classList.remove('no-touch');        
});

