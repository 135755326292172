/** 
 * LOADER MANAGER
 * Consente di attaccare/rimuovere degli eventi ad una coda, quando la coda non contiene più
 * elementi viene eseguito il callback definito (es. far sparire un loader).
 * (per gestire successione delle operazioni iniziali attraverso script multipli)
 * Consente di far partire un evento di loading.
 *
 * MOdificare le funzioni onStart e onStop per personalizzarne il comportamento,
 * rispettivamente, per l'inizio e la fine del caricamento. 
 *
 * Richiede jquery 2+ se si vuole usare funzionalità di progressBar.
 */

 class LoaderManager 
 {
 
     constructor() {
         this.currentEvents = [];
         this.isLoading = true;
     }
 
     addEvent(event_name) {
 
         //console.log(isLoading);
         if (this.isLoading == false) { this.start(); }
 
         // controlla che l'evento non sia già presente
         if (event_name && this.currentEvents.indexOf(event_name) == -1) { this.currentEvents.push(event_name); }
 
         //console.log("Event added: " + event_name);
 
 
     };
 
     eventComplete(event_name) {
 
         //console.log("Event complete: " + event_name);
 
         // rimuovo elemento da array     
         if (event_name && this.currentEvents.indexOf(event_name) >= 0) { this.currentEvents.splice(this.currentEvents.indexOf(event_name), 1); }
 
         //console.log("Events queue: ", currentEvents);
 
         if (!this.currentEvents.length) {
 
             //console.log("Events completed!!");
             this.isLoading = false;
 
             this.stop();
 
         }
 
     };
 
     start() {
         return this.onStart();
     };
 
     stop() {
 
         this.messageClear();
 
         this.progressBarClear(); // se aggiunta precedentemente
 
         return this.onStop();
 
     };
 
 
     progressBar() {
 
         this.progressBarClear(); // se già aggiunta precedentemente
 
         $('<div class="loadermanager-progress"><div class="loadermanager-progressbar"></div></div>').appendTo("body");
 
     };
 
     progressBarUpdate(percentComplete) {
 
         $("body").find(".loadermanager-progressbar").width(percentComplete + '%');
 
         return this;
 
     };
 
     progressBarClear() {
         $("body").find(".loadermanager-progress").remove();
         return this;
     };
 
 
     /**
      * Messaggio
      */
     message(msg) {
 
         this.messageClear();
 
         $('<div class="loadermanager-dialog">' + msg + '</div>').prependTo("html");
 
         return this;
     };
 
 
     /**
      * Rimozione messaggio
      */
     messageClear() {
 
         $("html").find(".loadermanager-dialog").remove();
 
         return this;
     }
 
 
     /**
      * eseguita a inizio caricamento
      * Va personalizzata a piacere.
      */
     onStart() {
         $("html").removeClass("loadermanager-page_loaded");
 
         return this;
     };
 
     /**
      * eseguita a fine caricamento
      * Va personalizzata a piacere.
      */
     onStop() {
         $("html").removeClass('page_loading').addClass("loadermanager-page_loaded");
         $(document).trigger("loadermanager-page_loaded");
     };
 
 }
 
 export default LoaderManager;